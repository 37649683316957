export default {
  APP_ENV: process.env.APP_ENV || 'production',
  APP_VERSION: process.env.BITBUCKET_TAG || `v0.0.0-rc.${process.env.BITBUCKET_BUILD_NUMBER}`,
  BASE_URL: (process.env.BITBUCKET_PR_ID && `https://pr-${process.env.BITBUCKET_PR_ID}-events.feat.eo.nl`) || process.env.BASE_URL || 'https://events.eo.nl',
  BASE_URL_CANONICAL: process.env.BASE_URL_CANONICAL || 'https://events.eo.nl',
  EO_API_HOST: process.env.EO_API_HOST || 'https://prod-api.eo.nl',
  NEW_EO_API_HOST: process.env.NEW_EO_API_HOST || 'https://api.eo.nl',
  EO_API_VERSION: 'v1',
  PREPR_ENVIRONMENT_DOMAIN: undefined,
  PREPR_COMMUNITY_ID: undefined,
  PREPR: {
    environments: {
      'f7e9030a-3120-4646-872b-d3f2917246c9': {
        baseUrl: 'https://beam.eo.nl',
        name: 'BEAM',
        publicationId: '6e12dcd1-c1f6-4762-8738-76c59a0d1b1f',
        slug: 'beam-eo',
        label: 'beam',
      },
      '46d45ec7-bd56-4f56-82be-f2f7b5505887': {
        baseUrl: 'https://blauwbloed.eo.nl',
        name: 'Blauw Bloed',
        publicationId: '4afdeff2-2983-4cf7-afe2-a2d5ceabce22',
        slug: 'blauwbloed-eo',
        label: 'blauwbloed',
      },
      '9463c573-b089-43b1-9f15-04aa97088079': {
        baseUrl: 'https://over.eo.nl',
        name: 'Over de EO',
        publicationId: null,
        slug: 'corporate-eo',
        label: 'corporate',
      },
      'da5bff01-1d38-4f72-b68d-83202166002e': {
        baseUrl: 'https://dit.eo.nl',
        name: 'Dit',
        publicationId: '0b1ff6f8-ee1a-401f-9bf1-49e8dffcac14',
        slug: 'dit-eo',
        label: 'dit',
      },
      '9beb354c-dde5-4ef9-87c8-5515b09343a7': {
        baseUrl: 'https://ditisdedag.eo.nl',
        name: 'Dit is de dag',
        publicationId: 'b441ffe7-c394-4393-90bd-2e2065d4795e',
        slug: 'ditisdedag-eo',
        label: 'ditisdedag',
      },
      '3fc256c0-b074-4780-b401-20e562827537': {
        baseUrl: 'https://www.eo.nl',
        name: 'Eva',
        publicationId: 'ef6cd8a6-9fcd-49e0-85c2-03fdd38a710b',
        slug: 'eva-eo',
        label: 'eva',
      },
      '490d3a0c-6e0f-4d12-bacd-a92625ee8973': {
        baseUrl: null,
        name: 'EO',
        publicationId: null,
        slug: 'generieke-content-eo',
        label: 'generieke-content',
      },
      '94dcecf2-1725-436c-9205-04f20bcffef0': {
        baseUrl: 'https://www.eo.nl',
        name: 'EO',
        publicationId: '78915b89-f177-440a-8f8f-62dfea174d62',
        slug: 'home-eo',
        label: 'home',
      },
      'd312f523-c7f7-4a46-816b-9d192b376914': {
        baseUrl: 'https://ikmisje.eo.nl',
        name: 'Ik mis je',
        publicationId: '1340eeaa-cb2e-4c7e-9be7-53cd362e3a19',
        slug: 'ikmisje-eo',
        label: 'ikmisje',
      },
      '55177197-688f-4dde-8d1f-509f61babbe7': {
        baseUrl: 'https://www.eo.nl',
        name: 'Lazarus',
        publicationId: '8dbed7fe-74b2-4164-b73d-7bcb19b4cfda',
        slug: 'lazarus-eo',
        label: 'lazarus',
      },
      '48c62830-e604-48fa-bc4b-693a8962d21e': {
        baseUrl: 'https://www.eo.nl',
        name: 'EO Metterdaad',
        publicationId: '52e68b37-0573-49a5-b7bd-ad6f5c061878',
        slug: 'metterdaad-eo',
        label: 'metterdaad',
      },
      '9a669063-a896-4a1e-bc3a-661f02c3f5b7': {
        baseUrl: 'https://nederlandzingt.eo.nl',
        name: 'Nederland Zingt',
        publicationId: '92728d7c-f683-4c9f-9a43-e1cfcee1bb11',
        slug: 'nederlandzingt-eo',
        label: 'nederlandzingt',
      },
      'e44f1fbd-1a8b-40d0-9420-4184fed256ee': {
        baseUrl: 'https://stichting.metterdaad.nl',
        name: 'Stichting Metterdaad',
        publicationId: '7a6d27b3-ce37-4828-b919-9a5bd7538c7b',
        slug: 'stichtingmetterdaad-eo',
        label: 'stichtingmetterdaad',
      },
      'c21bea32-65a1-4b4b-a9d8-5f4544b4ff05': {
        baseUrl: 'https://www.eo.nl',
        name: 'Visie',
        publicationId: '943f11ab-2031-4eda-b77d-0ae968c4518b',
        slug: 'visie-eo',
        label: 'visie',
      },
    },
  },
}