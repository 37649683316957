const axios = require('axios')
const { constants } = require('../config')

axios.defaults.baseURL = `${constants.NEW_EO_API_HOST}`

export default async function ({ redirect, route }) {
  const { fullPath, path } = route
  if (path !== '/publication' && path !== '/publication/') {
    return
  }

  const errorPage = `/error${fullPath}`

  redirect(errorPage)
}
