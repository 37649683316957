import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6b33772e = () => interopDefault(import('../pages/_community/_event/index.vue' /* webpackChunkName: "pages/_community/_event/index" */))
const _2d559b32 = () => interopDefault(import('../pages/_community/_event/info.vue' /* webpackChunkName: "pages/_community/_event/info" */))
const _2c8f0c2f = () => interopDefault(import('../pages/_community/_event/programma.vue' /* webpackChunkName: "pages/_community/_event/programma" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/:community/:event",
    component: _6b33772e,
    name: "community-event"
  }, {
    path: "/:community/:event?/info",
    component: _2d559b32,
    name: "community-event-info"
  }, {
    path: "/:community/:event?/programma",
    component: _2c8f0c2f,
    name: "community-event-programma"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
