import axios from 'axios'
import constants from './constants'

const maxBatchSize = 1000 // Amount of items per call (Prepr only returns a maximum of 1000 items per call)
const maxPageNumber = 25  // Amount of sitemap-pages per resource (for now only implemented for articles)

/**
 * Function to get publications from the Prepr REST API recursively
 * @param {object} config
 * @returns {Array} Slugs of publications
 */
const get = async (config) => {
  const parameters = {
    showLastMod: true,
    ...config,
  }

  const {
    type,
    priority,
    resource,
    showLastMod,
    communityId,
    maxAmount,
    page,
    basePath,
  } = parameters

  let limit
  let offset = 0

  const apiHost = process.env.NEW_EO_API_HOST || 'https://api.eo.nl'

  if (page) {
    // 1 determine total amount of articles
    let {data: {meta}} = await axios.get(`${apiHost}/sitemap/${resource}`, {
      params: {
        communityId,
        limit: 1,
        offset: 0,
      },
    })
    // 2 determine the page size.
    limit = Math.ceil(meta.total / maxPageNumber)
    offset = (page - 1) * limit
  } else {
    offset = 0
    limit = maxBatchSize > maxAmount ? maxAmount : maxBatchSize
  }

  const params = {
    limit,
    offset,
    ...(communityId && { communityId }),
  }

  let {data: {items = [] }} = await axios.get(`${apiHost}/sitemap/${resource}`, {
    params, 
  })

  // Check if api call contains results
  if (!items || !items[0]) {
    return []
  }

  // Return all publications, including the recursive ones
  return [
    ...items.map(p => {
      const fullPath = `${basePath}/${p.slug}`
      if (type === 'sitemap') {
        return {
          lastmod: showLastMod && (p.changedOn || null),
          priority,
          url: fullPath,
        }
      }
    }),
  ]
}

const getSitemaps = () => {
  return [
    {
      path: '/sitemap.xml',
    },
    {
      path: '/sitemap-events.xml',
      exclude: ['/**'],
      routes: async () => {
        return await get({
          createUri: p => `/${constants.PREPR.environments[p.environmentId].label}/${p.slug}`,
          resource: 'events',
          type: 'sitemap',
        })
      },
      cacheTime: 1000 * 60 * 60, // One hour cache
    },
  ]
}

/**
 * @nuxtjs/sitemap
 * Nuxt config for generated sitemaps
 * ref: https://sitemap.nuxtjs.org/
 */
export const sitemap = {
  path: '/sitemap-index.xml',
  hostname: constants.BASE_URL,
  lastmod: new Date().toISOString(),
  gzip: true,
  sitemaps: [
    ...getSitemaps(),
  ],
}

